@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  width: 100%;
  height: 100%;
}
.dark {
  background-color: #1a202c;
  color: #e2e8f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeInOut {
  0%{opacity: 0;}
  10%{opacity: 1;}
  90%{opacity: 1;}
  100%{opacity: 0;}
}
.fadeInOut {
  animation: fadeInOut 4.0s;
  opacity: 0;
}